<template>
    <div class="elem-chart">
        <slot name="head"></slot>
        <div class="chart" v-show="isExist" :id="id" :style="{ height: height }"></div>
        <div class="empty" v-show="!isExist">暂无数据</div>
    </div>
</template>

<script>
import echarts from "echarts"

export default {
    data() {
        return {
            id: this.$core.randomString(),
            isExist: false,
        }
    },

    props: {
        // 配置项，优先度最高，该属性有值时其他配置无效
        option: {
            type: Object,
            required: false,
        },
        // 图表类型
        type: {
            type: String,
            required: false,
        },
        // 标题
        title: {
            type: String,
            required: false,
        },
        // 单项名称
        itemName: {
            type: String,
            required: false,
        },
        // 值
        value: {
            type: Array,
            required: false,
        },
        // 高度
        height: {
            type: String,
            required: false,
        },
    },

    watch: {
        option() {
            this.onChangeOption()
        },
        type() {
            this.onChangeOption()
        },
        title() {
            this.onChangeOption()
        },
        itemName() {
            this.onChangeOption()
        },
        value() {
            this.onChangeOption()
        },
    },

    mounted() {
        // 初始化
        this.chart = echarts.init(document.getElementById(this.id))
        // 初始配置项
        this.onChangeOption()
        // 监听窗口大小变化事件
        window.addEventListener("resize", () => {
            this.chart.resize()
        })
    },

    methods: {
        /**
         * 监听改变配置项事件，重新渲染
         */
        onChangeOption() {
            // 是否存在配置项，写入配置
            if (this.option) this.setOption(this.option)
            // 无自定义配置项，处理属性
            else this.processAttr()
        },

        /**
         * 处理属性
         */
        processAttr() {
            var option

            switch (this.type) {
                case "pie":
                    option = {
                        title: {
                            text: this.title,
                        },
                        tooltip: {
                            trigger: "item",
                        },
                        legend: {
                            bottom: "bottom",
                        },
                        series: [
                            {
                                name: this.itemName || this.title,
                                type: "pie",
                                data: this.value,
                                radius: ["30%", "40%"],
                            },
                        ],
                    }
                    break
                case "bar":
                    var v = this.getBarValue()

                    option = {
                        title: {
                            text: this.title,
                        },
                        tooltip: {
                            trigger: "axis",
                            axisPointer: {
                                type: "shadow",
                            },
                        },
                        xAxis: {
                            type: "category",
                            data: v.keys,
                        },
                        yAxis: {
                            type: "value",
                            scale: true,
                        },
                        series: [
                            {
                                data: v.values,
                                type: "bar",
                            },
                        ],
                    }
                    break
                case "line":
                    var value = this.getBarValue()

                    option = {
                        title: {
                            text: this.title,
                        },
                        tooltip: {
                            trigger: "axis",
                        },
                        xAxis: {
                            type: "category",
                            boundaryGap: false,
                            data: value.keys,
                        },
                        yAxis: {
                            type: "value",
                            scale: true,
                        },
                        series: [
                            {
                                name: this.itemName || this.title,
                                type: "line",
                                smooth: true,
                                data: value.values,
                                markLine: {
                                    data: [
                                        { type: "average" },
                                        [
                                            {
                                                symbol: "none",
                                                x: "90%",
                                                yAxis: "max",
                                            },
                                            {
                                                symbol: "circle",
                                                label: {
                                                    position: "start",
                                                },
                                                type: "max",
                                            },
                                        ],
                                    ],
                                },
                            },
                        ],
                    }
            }

            this.setOption(option)
        },

        /**
         * 获取柱状图的值
         */
        getBarValue() {
            if (!this.value) {
                return {}
            }

            const keys = []
            const values = []

            for (let i = 0, l = this.value; i < l.length; i++) {
                let v = l[i]

                keys.push(v.name)
                values.push(v.value)
            }

            return { keys, values }
        },

        /**
         * 写入配置项
         */
        setOption(option) {
            if (option && Object.keys(option).length > 0) {
                this.isExist = true
            } else return (this.isExist = false)

            !option.grid &&
                (option.grid = {
                    top: "10%",
                    left: "10%",
                    right: "10%",
                    bottom: "10%",
                })

            this.$nextTick(() => {
                // 切换类型时，清空实例，避免残留
                this.chart?.clear()
                // 写入配置项
                this.chart?.setOption(option)
                // 重置大小
                this.chart.resize()
            })
        },
    },
}
</script>

<style lang="less" scoped>
.elem-chart {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid #f3f3f3;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    .chart {
        width: 100%;
        flex-grow: 1;
    }

    .empty {
        color: #888;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
